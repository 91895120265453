import React from 'react'
import { navigate } from 'gatsby'

import css from './seaspray.sass'

const SeaSpray = ({ data: { title, body, button } }) => (
  <section className="seaspray-container">
    <div className="seaspray-content">
      <h1>{title}</h1>
      <div className="seaspray-body typeface-large">{body}</div>
      <button type="button" className="seaspray-button" onClick={() => navigate('/team')}>{button}</button>
    </div>
  </section>
)

export default SeaSpray
