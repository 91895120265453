import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Blurb from '../components/blurb'
import Img from 'gatsby-image'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  quote,
  title,
  helmet,
  image,
  imageByline,
  links,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="blog-post">
      {helmet || ''}
      <Blurb title={title} body={description} isMarkdown />
      <article className="blog-post-content">
        <Img className="avatar" fluid={image.childImageSharp.fluid} style={{ width: '100%' }} />
        <div className="image-by-line">{imageByline}</div>

        <div className="column-container">
          <div className="quote narrow">
            "{quote}"
          </div>
          <PostContent content={content} className="blog-post-text" />
          <div className="blog-post-extras">
            <div className="quote wide">
              "{quote}"
            </div>
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <span style={{ fontSize: '24px', fontWeight: '600' }}>Tags</span>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {links && links.length ? (
              <div style={{ marginTop: `4rem` }}>
                <span style={{ fontSize: '24px', fontWeight: '600' }}>Related links</span>
                <ul className="linklist">
                  {links.map(link => (
                    <li key={link + `link`}>
                      <a href={link}>{link}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </article>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  const helmet = (
    <Helmet titleTemplate="%s | Blog">
      <title>{`${post.frontmatter.title}`}</title>
      <meta name="description" content=""></meta>
      <meta property="og:type" content="article" />
      <meta
        property="og:title"
        content={`${post.frontmatter.title}`}
      />
      <meta
        property="og:description"
        content={`${post.frontmatter.description}`}
      />
      <meta
        property="og:image"
        content={`${'https://www.surfncode.io'}${post.frontmatter.image.childImageSharp.fluid.src}`}
      />

      <meta
        name="twitter:description"
        content={`${post.frontmatter.description}`}
      />
      <meta
        property="twitter:image"
        content={`${'https://www.surfncode.io'}${post.frontmatter.image.childImageSharp.fluid.src}`}
      />
      <meta property="twitter:url" content={`${'https://www.surfncode.io'}`} />
      <meta property="og:url" content={`https://www.surfncode.io${post.fields.slug}`} />

    </Helmet>
  )

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={helmet}
        tags={post.frontmatter.tags}
        quote={post.frontmatter.quote}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        imageByline={post.frontmatter.imageByline}
        links={post.frontmatter.links}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        quote
        image  {
          childImageSharp {
            fluid(maxWidth: 1170, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageByline
      }
    }
  }
`
