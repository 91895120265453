import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.png'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import linkedin from '../img/social/linkedin_white.png'

import css from './footer.sass'

const Footer = () => (
  <footer className="footer has-background-black has-text-white-ter">
    <div className="footer-content">
      <span className="links">
        <a
          title="instagram"
          href="https://www.instagram.com/surfncode/"
          target="_blank"
          rel="noopener norefferer"
        >
          <img
            src={instagram}
            alt="Instagram"
            style={{ width: '1em', height: '1em' }}
          />
        </a>
        <a
          title="twitter"
          href="https://twitter.com/surfncode"
          target="_blank"
          rel="noopener norefferer"
        >
          <img
            className="fas fa-lg"
            src={twitter}
            alt="Twitter"
            style={{ width: '1em', height: '1em' }}
          />
        </a>
        <a
          title="facebook"
          href="https://www.facebook.com/surfncode/"
          target="_blank"
          rel="noopener norefferer"
        >
          <img
            src={facebook}
            alt="Facebook"
            style={{ width: '1em', height: '1em' }}
          />
        </a>
        <a
          title="vimeo"
          href="https://www.linkedin.com/company/surfncode/"
          target="_blank"
          rel="noopener norefferer"
        >
          <img
            src={linkedin}
            alt="LinkedIn"
            style={{ width: '1em', height: '1em' }}
          />
        </a>
      </span>
      <span className="copyright">
        <span>© SURFnCODE Limited 2019</span>
        <Link to="/legal" className="large">PRIVACY</Link>
      </span>
    </div>
  </footer>
)

export default Footer
