import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import 'normalize.css'
import './brand.sass'
// import './all.sass'

const TemplateWrapper = ({ children, location, meta = {} }) => {
  console.log('META', meta)
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet>
            <html lang="en" />
            <title>{meta.title || data.site.siteMetadata.title}</title>
            <meta
              name="description"
              content={meta['og:description'] || data.site.siteMetadata.description}
            />

            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/img/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              href="/img/favicon.png"
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href="/img/favicon.png"
              sizes="16x16"
            />

            <link
              rel="mask-icon"
              href="/img/safari-pinned-tab.svg"
              color="#127aeb"
            />
            <meta name="theme-color" content="#fff" />
            <link href="https://fonts.googleapis.com/css?family=Barlow:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css?family=Barlow:400,400i,700,700i" rel="stylesheet"></link>
            <meta property="og:type" content={meta.type || 'business.business'} />
            <meta property="twitter:type" content={meta.type || 'business.business'} />
            <meta property="og:title" content={meta['og:title'] || data.site.siteMetadata.title} />
            <meta property="twitter:title" content={meta['og:title'] || data.site.siteMetadata.title} />
            <meta
              property="og:description"
              content={meta['og:description'] || data.site.siteMetadata.description}
            />
            <meta
              name="twitter:description"
              content={meta['og:description'] || data.site.siteMetadata.description}
            />
            <meta property="og:url" content={meta.url || 'https://www.surfncode.io'} />
            <meta property="twitter:url" content={meta.url || 'https://www.surfncode.io'} />
            <meta property="og:image" content="https://www.surfncode.io/img/thumbnail.png" />
            <meta property="twitter:image" content="https://www.surfncode.io/img/thumbnail.png" />
          </Helmet>
          <Navbar location={location} />
          <div className="page">
            <main className="layout">{children}</main>
            <Footer />
          </div>
        </>
      )}
    />
  )
}

export default TemplateWrapper
