import React, { useState } from 'react'
import { Link } from 'gatsby'
import github from '../img/github-icon.svg'
import logo from '../img/logo.png'
import logoWhite from '../img/logo_white.png'
import logoBlue from '../img/logo_blue.png';
import { Location } from '@reach/router'

import css from './navbar.sass'

const Navbar = () => {
  const [active, setActive] = useState(false)
  const [navBarActiveClass, setNavBarActiveClass] = useState('')

  const toggleHamburger = () => {
    // toggle the active boolean in the state
    setActive(!active)
    setNavBarActiveClass(active ? 'is-active' : '')
  }

  const regex = /^https?:\/\/(localhost:8000|www.surfncode\.io|epic-murdock-658a3d.netlify.com)\/?$/ig
  let location = 'false'
  if (typeof window !== `undefined`) {
    location = window.location.href
  }
  return (
    <nav
      className={`navbar is-transparent ${regex.test(location) ? 'fadein' : ''}`}
      role='navigation'
      aria-label='main-navigation'
    >
      {active && <div className="menu-container is-active">
        <div className="menu">
          <LinkBlock />
        </div>
      </div>}

      <div className='container'>
        <div className='navbar-brand'>
          <Link to='/' className='navbar-item' title='Logo'>
            <img
              src={active ? logoWhite : logoBlue}
              alt='Kaldi'
              style={{ width: '116px', position: 'absolute', top: '20px' }}
            />
          </Link>
        </div>

        <div
          id='navMenu'
          className={`navbar-menu ${navBarActiveClass}`}
        >
          <div className={`navbar-start has-text-centered ${navBarActiveClass}`}>
            <LinkBlock />
          </div>

          <div
            className={`hamburguer ${active ? 'is-open' : ''}`}
            onClick={toggleHamburger}
          />
        </div>
      </div>
    </nav>
  )
}

const LinkBlock = () => (
  <>
    <Link className='navbar-item mobile-only' activeClassName="selected" to='/'>
        Home
    </Link>
    <Link className='navbar-item' activeClassName="selected" to='/pre-seed'>
        Pre-Seed
    </Link>
    <Link className='navbar-item' activeClassName="selected" to='/seed'>
        Seed
    </Link>
    <Link className='navbar-item' activeClassName="selected" to='/team'>
        Team
    </Link>
    <Link className='navbar-item' activeClassName="selected" to='/partners'>
        Partners
    </Link>
    <Link className='navbar-item' activeClassName="selected" to='/journal'>
        Journal
    </Link>
    <Link className='navbar-item' activeClassName="selected" to='/location'>
        Location
    </Link>
    <Link className='navbar-item button' activeClassName="selected" to='/apply'>
        Apply Now
    </Link>
  </>
)

export default () => (
  <Location>
    {({ navigate, location }) => <Navbar location={navigate} />}
  </Location>
)
