import React from 'react'
import { navigate } from 'gatsby'

import css from './calltoaction.sass'

const CallToAction = ({ title, ctas }) => (
  <section className="cta-container">
    <h1>{title}</h1>
    <div className="cta-content">
      {ctas.map(({ title, subTitle, body, button, link }) => (
        <div className="cta" key={title}>
          <h5 className="cta-title">{title}</h5>
          <div className="cta-subtitle">{subTitle}</div>
          <div className="cta-body">{body}</div>
          <button 
            type="button" 
            className="cta-button"
            onClick={() => navigate(link)}
          >{button}</button>
          <div className="cta-background">
          </div>
        </div>
      ))}
    </div>
  </section>
)

export default CallToAction
