import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
// import GoogleMaps from 'simple-react-google-maps'

import Blurb from '../components/blurb'
import Contact from '../components/contact'
import Buffer from '../components/buffer'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const LocationTemplate = ({
  title, body, image,
}) => {
  return (
    <>
        <Buffer />
      <div className="contact-landing">
        <Blurb
          title={title}
          body={body}
        />
      </div>
      <section className="banner">
        <img src={image.childImageSharp.fluid.src} />
      </section>
      <Contact
        data={{
          title: 'Come surf with us',
          body: 'R. Mouzinho de Albuquerque, 738, 2˚ Piso, 4450-007 Matosinhos',
          button: 'Contact us',
          address: 'hello@surfncode.io',
        }}
      />
    </>
  )
}

LocationTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const LocationPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <LocationTemplate
        contentComponent={HTMLContent}
        {...post.frontmatter}
        content={post.html}
      />
    </Layout>
  )
}

export default LocationPage

export const locationPageQuery = graphql`
  query LocationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        body
        image{
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
/*
  <section className="map">
    <GoogleMaps
      className="my-map transparent-background"
      apiKey={''}
      style={{ height: '400px', width: '1070px' }}
      zoom={17}
      center={{ lat: 41.17525, lng: -8.686 }}
      markers={{ lat: 41.17525, lng: -8.686 }}
    /> \
  </section>
*/
