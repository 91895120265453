import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Contact from '../components/contact'
import Blurb from '../components/blurb'
import WaveFlow from '../components/waveflow'
import Layout from '../components/Layout'
import Buffer from '../components/buffer'

export const PreSeedTemplate = ({
  mainPitch,
  subPitch,
  sections,
  contact,
}) => (
  <>
    <Buffer />
    <Blurb title={mainPitch} body={subPitch}/>
    <WaveFlow sections={sections} />
    <Contact data={contact[0]} />
  </>
)

PreSeedTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const PreSeedPage = ({ data }) => {
  console.log(data)
  const { markdownRemark: post } = data

  return (
    <Layout>
      <PreSeedTemplate
        {...post.frontmatter}
        content={post.html}
      />
    </Layout>
  )
}

export default PreSeedPage

export const preSeedPageQuery = graphql`
  query PreSeedPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        mainPitch
        subPitch
        highlights {
          highlight
        }
        sections {
          title
          body
          quote
        }
        contact{
          title
          body
          button
          address
        }
      }
    }
  }
`
