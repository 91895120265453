import React from 'react'
import { Link } from 'gatsby'

import css from './contact.sass'

const Contact = ({ data: { title, body, button, address } }) => (
  <section className="contact  full-width">
    <div className="contact-content">
      <h3 className="contact-title">{title}</h3>
      <p className="contact-body">{body}</p>
      {
        /@/.test(address)
          ? <a className="contact-button" href={`mailto:${address}`}>{button}</a>
          : /^https?:\/\/(\w+\.)*\w+\.\w+/gmi.test(address)
            ? <a className="contact-button" href={address}>{button}</a>
            : <Link className="contact-button" to={address}>{button}</Link>
      }
    </div>
    <div className="contact-backgrounded">
    </div>
  </section>
)

export default Contact
