import React from 'react'
import { HTMLContent } from './Content'

import css from './waveflow.sass'

const WaveFlow = ({ sections }) => (
  <section className="wave-flow">
    {sections.map(({ title, body, quote }, index) => (
      <article className="wave-element" key={title} >
        <h3 className="wave-element-title">{title}</h3>
        <HTMLContent content={body} />
        {quote && <div className="quote">
          <q>{quote}</q>
        </div>}
      </article>
    ))}
  </section>
)

export default WaveFlow

/* <span className="sig-name">Count on our team for every aspect of your journey.</span>
<span className="sig-role">SURFnCODE Co-Founder & CEO</span> */
/* <span className="sig-name">Bethany Hamilton</span>
<span className="sig-role">Pro surfer & shark attack survivor</span> */
