import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Concertina from '../components/concertina'
import Blurb from '../components/blurb'
import PeopleGallery from '../components/peoplegallery'
import Contact from '../components/contact'
import Buffer from '../components/buffer'

import linkedin from '../img/social/linkedin.png'

export const TeamTemplate = ({
  foundersTitle,
  founders,
  teamTitle,
  teamBody,
  team,
  careersTitle,
  careersBody,
  philosophyTitle,
  philosophyLink,
  philosophy,
  contact
}) => (
    <>
      <Buffer />

      <Blurb
        title={'Get to know us'}
        body={'It takes a village - our multi-disciplinary approach ensures founders get the support and validation they need in order to succeed. Get to know the team that has your back.'}
      />
      <PeopleGallery title={foundersTitle} people={founders} className="founders" />

      {/* <Blurb title={teamTitle} body={teamBody} />

    <PeopleGallery people={team} className="team"/> */}

      {/* <Blurb title={careersTitle} body={careersBody} /> */}

      <a name="philosophy" />
      <section className="philosophy">
        <Concertina
          title={philosophyTitle}
          link={philosophyLink}
          data={philosophy}
        />
      </section>

      <Contact data={contact[0]} />
    </>
  )

TeamTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const TeamPage = ({ data }) => {
  console.log(data)
  const { markdownRemark: post } = data

  return (
    <Layout>
      <TeamTemplate
        contentComponent={HTMLContent}
        {...post.frontmatter}
        content={post.html}
      />
    </Layout>
  )
}

export default TeamPage

export const teamPageQuery = graphql`
  query TeamPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        foundersTitle
        founders{
            name
            role
            bio
            link
            image {
              childImageSharp {
                fluid(maxWidth: 270, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
        teamTitle
        teamBody
        team{
            name
            role
            link
            image {
              childImageSharp {
                fluid(maxWidth: 270, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
        careersTitle
        careersBody
        philosophyTitle
        philosophyLink
        philosophy{
          title
          body
        }
        contact{
          title
          body
          button
          address
        }
      }
    }
  }
`
