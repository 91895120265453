import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import WaveFlow from '../components/waveflow'
import CallToAction from '../components/calltoaction'
import Contact from '../components/contact'
import SeaSpray from '../components/seaspray'
import MainPitch from '../components/mainpitch'
import BlogRoll from '../components/BlogRoll'

import { components } from '../../.cache/sync-requires'

export const IndexPageTemplate = ({
  mainPitch,
  subPitch,
  highlights,
  highlightTitle,
  sections,
  ctaTitle,
  ctas,
  balance,
  contact
}) => (
  <>
    <MainPitch main={mainPitch} sub={subPitch}/>

    <WaveFlow sections={sections} />

    <CallToAction title={ctaTitle} ctas={ctas} />

    <SeaSpray data={balance[0]} />

    <Contact data={contact[0]} />
  </>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  console.log(frontmatter)
  return (
    <Layout>
      <IndexPageTemplate
        {...frontmatter}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        mainPitch
        subPitch
        highlights{
          highlight
        }
        highlightTitle
        sections {
          title
          body
          quote
        }
        ctaTitle
        ctas{
          title
          subTitle
          body
          button
          link
        }
        balance{
          title
          body
          button
        }
        contact{
          title
          body
          button
          address
        }
      }
    }
  }
`

/* <section className="highlights-container">
      <h2>{highlightTitle}</h2>
      <ul className="highlight-list">
        {highlights.map(({ highlight }) => <li key={highlight} className="highlight">{highlight}</li>)}
      </ul>
      </section> */
