import React from 'react'

import waveSun from '../img/brand/wave-sun.png'

import css from './mainpitch.sass'

const MainPitch = ({ main, sub, flat = false }) => (
  <section className="main-pitch-container">
    <div className={`long-wave ${flat ? 'flat' : 'animate'}`} style={{ backgroundImage: waveSun }}>
    </div>
    <div className="main-pitch">
      <h1>{main}</h1>
      <div className="typeface-large sub-pitch">{sub}</div>
      {!flat && <p className="main-pitch-chevron">&#8964;</p>}
    </div>
  </section>
)

export default MainPitch
