import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import Blurb from '../components/blurb'
import Buffer from '../components/buffer'

export const JournalTemplate = ({
  title, description,
}) => {
  return (
    <>
      <Buffer />
      <Blurb title={title} body={description} isMarkdown />
      <BlogRoll />
    </>
  )
}

JournalTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

const JournalPage = ({ data }) => {
  console.log(data)
  const { markdownRemark: post } = data

  return (
    <Layout
      meta={{
        title: 'Journal',
        type: 'article',
        'og:title': post.frontmatter.title,
        'og:description': post.frontmatter.description,
        url: 'https://www.surfncode.io/journal',
      }}
    >
      <JournalTemplate
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
    </Layout>
  )
}

export default JournalPage

export const journalPageQuery = graphql`
  query JournalPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
