import React, { Fragment, useState } from 'react'

import css from './concertina.sass'

const Concertina = ({ title, link, data }) => {
  const [openSection, setOpenSection] = useState(false)
  return (
    <div className="concertina">
      <h5 className="concertina-title">{title}</h5>
      <span className="concertina-link" >
        Checkout &nbsp;
        <a href={link} target="_blank" rel="noopener norefferer">The original code</a>
      </span>
      {data.map(({ title, body }, index) => (
        <section className="concertina-section" key={title}>
          <h3
            className={`title ${openSection === index ? 'open' : 'closed'}`}
            onClick={() => setOpenSection(index === openSection ? false : index)}
          >
            <span className="title-number">
              {'00'.substring(0, 2 - (String(index + 1).length)) + (index + 1)}
            </span>{title}
          </h3>
          <div
            className={`body ${openSection === index ? 'open' : 'closed'}`}
          >
            {body}
          </div>
        </section>
      ))}
    </div>
  )
}

export default Concertina
