const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => m && m.default || m


exports.components = {
  "component---src-templates-index-page-js": hot(preferDefault(require("/opt/build/repo/src/templates/index-page.js"))),
  "component---src-templates-apply-page-js": hot(preferDefault(require("/opt/build/repo/src/templates/apply-page.js"))),
  "component---src-templates-blog-post-js": hot(preferDefault(require("/opt/build/repo/src/templates/blog-post.js"))),
  "component---src-templates-journal-page-js": hot(preferDefault(require("/opt/build/repo/src/templates/journal-page.js"))),
  "component---src-templates-location-page-js": hot(preferDefault(require("/opt/build/repo/src/templates/location-page.js"))),
  "component---src-templates-partners-page-js": hot(preferDefault(require("/opt/build/repo/src/templates/partners-page.js"))),
  "component---src-templates-pre-seed-page-js": hot(preferDefault(require("/opt/build/repo/src/templates/pre-seed-page.js"))),
  "component---src-templates-seed-page-js": hot(preferDefault(require("/opt/build/repo/src/templates/seed-page.js"))),
  "component---src-templates-legal-page-js": hot(preferDefault(require("/opt/build/repo/src/templates/legal-page.js"))),
  "component---src-templates-team-page-js": hot(preferDefault(require("/opt/build/repo/src/templates/team-page.js"))),
  "component---src-templates-tags-js": hot(preferDefault(require("/opt/build/repo/src/templates/tags.js"))),
  "component---src-pages-404-js": hot(preferDefault(require("/opt/build/repo/src/pages/404.js"))),
  "component---src-pages-tags-index-js": hot(preferDefault(require("/opt/build/repo/src/pages/tags/index.js")))
}

