import React from 'react'

import Img from 'gatsby-image'

import css from './peoplegallery.sass'

const PeopleGallery = ({ title, people, className }) => {
  return (
    <section className={`image-gallery ${className}`}>
      {title && <h3 className="title">{title}</h3>}
      <section className="gallery">
        {people.map(({ name, role, bio, link, image }) => <div key={role + name} className="user-card">
          <Img className="avatar" fluid={image.childImageSharp.fluid} style={{ position: 'absolute' }}/>
          <div className="name">{name}</div>
          <div className="role">{role}</div>
          {bio && <div className="bio">{bio}</div>}
          <a
            className="linkedin"
            href={`https://www.linkedin.com/in/${link}`}
            target="_blank"
            rel="noopener norefferer"
          >&nbsp;</a>
        </div>)}
      </section>
    </section>
  )
}

export default PeopleGallery
