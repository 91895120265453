import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import css from './BlogRoll.sass'
class BlogRoll extends React.Component {
  render () {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <section className="blog-roll">
        {posts &&
        posts.map(({ node: { id, fields, frontmatter, excerpt } }) => (
          <article className="blog-tile" key={id}>
            <Link className="title" to={fields.slug}>
              <Img className="blog-tile-thumb" fluid={frontmatter.image.childImageSharp.fluid} />
            </Link>
            <div className="blog-tile-card">
              <Link className="title" to={fields.slug}>
                <h6>
                  {frontmatter.title}
                </h6>
              </Link>
              <div className="excerpt">
                {excerpt}
              </div>
              <div className="date">
                {frontmatter.date}
              </div>
              <Link className="arrow" to={fields.slug}>
                  →
              </Link>
            </div>
          </article>
        ))}
      </section>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 120)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                image  {
                  childImageSharp {
                    fluid(maxWidth: 270, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
