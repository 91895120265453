import React from 'react'
import MarkdownContent from '../components/MarkdownContent';

import css from './blurb.sass'

const Blurb = ({ title, body, isMarkdown }) => (
  <section className="blurb" >
    <div className="badge"/>
    <h1 className="title">{title}</h1>
    {isMarkdown ? (
      <MarkdownContent className="body typeface-large" content={body} />
    ) : (
      <div className="body typeface-large">{body}</div>
    )}
  </section>
)

export default Blurb
