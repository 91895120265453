import React, { useState } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { ToastContainer, toast } from 'react-toastify'
import { graphql } from 'gatsby'

import Modal from 'react-responsive-modal'

import 'react-toastify/dist/ReactToastify.min.css'

import Layout from './Layout'

import css from './ApplyForm.sass'

const ApplyForm = ({ title, description }) => {
  // const [isValidated, setIsValidated] = useState(false)
  const [isRequired] = useState(true)
  const [isSending, setIsSending] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [linkedin, setLinkedin] = useState('www.linkedin.com/in/')
  const [country, setCountry] = useState('')
  const [region, setRegion] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [url, setUrl] = useState('')
  const [pitch, setPitch] = useState('')
  const [companyStatus, setCompanyStatus] = useState('')
  const [founder, setFounder] = useState('')
  const [founders, setFounders] = useState([])
  const [fundingRequirements, setFundingRequirements] = useState(0)
  const [help, setHelp] = useState('')
  const [fundingSelf, setFundingSelf] = useState(false)
  const [fundingFriendsAndFamily, setFundingFriendsAndFamily] = useState(false)
  const [fundingAngel, setFundingAngel] = useState(false)
  const [fundingOther, setFundingOther] = useState(false)
  const [fundingOtherSource, setFundingOtherSource] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    setIsSending(true)
    setIsModalOpen(false)
    const urls = [
      'https://hooks.zapier.com/hooks/catch/5234144/oyitv33/', // slack
      'https://hooks.zapier.com/hooks/catch/5234144/oyits63/', // email
      'https://hooks.zapier.com/hooks/catch/5234144/oyih0hd/', // trello
    ]

    const processedFoundersList = `${
      founders.map(({ name, isFulltime }) => `- ${name}${isFulltime ? ' -> working fulltime ' : ''}`).join('\n')
    }`

    const fundingStatus = `${
      fundingSelf ? ' - Self funded; \n' : ''
    } ${
      fundingFriendsAndFamily ? ' - Funded by friends and family; \n' : ''
    } ${
      fundingAngel ? ' - Angel funded ' : ''
    }`

    const payload = {
      firstName,
      lastName,
      email,
      linkedin,
      country: `${region ? `${region}, ` : ''}${country}`,
      region,
      companyName,
      url,
      pitch,
      companyStatus,
      founders: processedFoundersList,
      fundingRequirements,
      help,
      fundingStatus,
      fundingOther: fundingOther ? fundingOtherSource : 'none',
    }

    const grabContent = url => fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then(res => res.json())
      .then(console.log)

    Promise
      .all(urls.map(grabContent))
      .then(() => {
        setIsSending(false)
        toast.success('Your application has been received!! You can expect to hear back from us shortly.')
      })
      .catch(() => {
        setIsSending(false)
        toast.error('Oops. There was an error processing your request. Please try again later.')
      })
  }

  const handleInputChange = setter => e => setter(e.target.value)

  const handleAddFounder = e => {
    if (e.key === 'Enter' || e.type === 'click') {
      e.preventDefault()
      const multiple = founder.split(',').map(each => ({ name: each, isFulltime: false }))
      setFounders([
        ...founders,
        ...multiple,
      ])
      setFounder('')
    }
  }

  const handleRemoveFounder = index => () => {
    setFounders([
      ...founders.slice(0, index),
      ...founders.slice(index + 1),
    ])
  }

  const toggleFulltime = index => () => {
    setFounders([
      ...founders.slice(0, index),
      {
        name: founders[index].name,
        isFulltime: !founders[index].isFulltime,
      },
      ...founders.slice(index + 1),
    ])
  }

  const askForConsent = e => {
    e.preventDefault()
    setIsModalOpen(true)
  }

  const consentDenied = () => setIsModalOpen(false)

  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>{title}</h1>
            <form
              name="contact"
              onSubmit={askForConsent}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <div>{description}</div>
              <div hidden>
                <label>
                  Don’t fill this out:{' '}
                  <input name="bot-field" onChange={handleInputChange} />
                </label>
              </div>
              {/* First Name*  */}
              <div className="field">
                <label className="label" htmlFor={'firstName'}>
                  <strong>First name *</strong>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'firstName'}
                    onChange={handleInputChange(setFirstName)}
                    id={'firstName'}
                    required={isRequired}
                    value={firstName}
                  />
                </div>
              </div>
              {/* Last Name*  */}
              <div className="field">
                <label className="label" htmlFor={'lastName'}>
                  <strong>Last name *</strong>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'lastName'}
                    onChange={handleInputChange(setLastName)}
                    id={'lastName'}
                    required={isRequired}
                    value={lastName}
                  />
                </div>
              </div>
              {/* Email*  */}
              <div className="field">
                <label className="label" htmlFor={'email'}>
                  <strong>Email *</strong>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'email'}
                    name={'email'}
                    onChange={handleInputChange(setEmail)}
                    id={'email'}
                    required={isRequired}
                    value={email}
                  />
                </div>
              </div>
              {/* Linkedin profile*  */}
              <div className="field">
                <label className="label" htmlFor={'linkedin'}>
                  <strong>LinkedIn profile *</strong>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'linkedin'}
                    onChange={handleInputChange(setLinkedin)}
                    id={'linkedin'}
                    required={isRequired}
                    value={linkedin}
                  />
                </div>
              </div>
              {/* Country*  */}

              <div className="field">
                <label className="label" htmlFor={'country'}>
                  <strong>Country *</strong>
                </label>
                <div className="control">
                  <CountryDropdown
                    className="input"
                    name={'country'}
                    id={'country'}
                    value={country}
                    onChange={setCountry}
                  />
                </div>
                {country && <>
                  <label className="label" htmlFor={'region'}>
                    <strong>Region</strong>
                  </label>
                  <RegionDropdown
                    className="input"
                    name={'region'}
                    id={'region'}
                    value={region}
                    onChange={setRegion}
                    country={country}
                  />
                </>}
              </div>
              {/* Company name (if you have one - don’t worry if you don’t!)  */}
              <div className="field">
                <label className="label" htmlFor={'companyName'}>
                  <strong>Company name</strong> (if you have one - don’t worry if you don’t!)
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'companyName'}
                    onChange={handleInputChange(setCompanyName)}
                    id={'companyName'}
                    value={companyName}
                  />
                </div>
              </div>
              {/* URL if any  */}
              <div className="field">
                <label className="label" htmlFor={'url'}>
                  <strong>URL</strong> (if any)
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'url'}
                    onChange={handleInputChange(setUrl)}
                    id={'url'}
                    value={url}
                  />
                </div>
              </div>
              {/* Quick pitch: describe your idea or business; tell us a
               brief history if you already have traction; describe your target market, the pain
               you're addressing and your current or proposed solution. You can add any supporting links.*  */}
              <div className="field">
                <label className="label" htmlFor={'pitch'}>
                  <strong>Quick pitch: describe your idea or business * </strong>
                  Tell us a brief history if you already have traction;
                  describe your target market, the pain you're addressing and your current or proposed solution.
                  You can add any supporting links.
                </label>
                <div className="control">
                  <textarea
                    className="textarea"
                    name={'pitch'}
                    onChange={handleInputChange(setPitch)}
                    id={'pitch'}
                    required={isRequired}
                    rows="6"
                    value={pitch}
                  />
                </div>
              </div>
              {/* Current Company Status (incorporated, not yet). If incorporated, where?*  */}
              <div className="field">
                <label className="label" htmlFor={'companyStatus'}>
                  <strong>Current Company Status (incorporated, not yet). If incorporated, where? *</strong>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'companyStatus'}
                    onChange={handleInputChange(setCompanyStatus)}
                    id={'companyStatus'}
                    required={isRequired}
                    value={companyStatus}
                  />
                </div>
              </div>
              {/* List all the founders*  */}
              {/* Who’s working full-time?  */}
              <div className="field">
                <label className="label" htmlFor={'founder'}>
                  <strong>List all the founders *:</strong> One at a time, or multiple at once, separated by commas
                </label>
                <div className="control with-button">
                  <input
                    className="input"
                    type={'text'}
                    name={'founder'}
                    onChange={handleInputChange(setFounder)}
                    id={'founder'}
                    value={founder}
                    onKeyDown={handleAddFounder}
                  />
                  <button type="button" onClick={handleAddFounder}>&#43;</button>
                </div>
                {founders.map(({ name, isFulltime }, index) => <div className="founder" key={name}>
                  <div className="founder-name">
                    <div>{name}</div>
                    <button type="button"onClick={handleRemoveFounder(index)}>-</button>
                  </div>
                  <div className="founder-is-fulltime">
                    <input
                      className="is-fulltime-checkbox"
                      type="checkbox"
                      checked={isFulltime}
                      onChange={toggleFulltime(index)}
                    />
                    <span>Working fulltime</span>
                  </div>
                </div>)}
              </div>
              {/* Current funding* (*multipla)  */}
              <div className="field">
                <label className="label">
                  <strong>Current funding *</strong>
                </label>
                <div className="funding-input">
                  <input
                    type="checkbox"
                    id="self"
                    name="self"
                    onChange={() => setFundingSelf(!fundingSelf)}
                    checked={fundingSelf}
                  />
                  <label htmlFor="self"><strong>Self funded</strong></label>
                </div>

                <div className="funding-input">
                  <input
                    type="checkbox"
                    id="friends_and_family"
                    name="friends_and_family"
                    onChange={() => setFundingFriendsAndFamily(!fundingFriendsAndFamily)}
                    checked={fundingFriendsAndFamily}
                  />
                  <label htmlFor="friends_and_family"><strong>Friends & family</strong></label>
                </div>
                <div className="funding-input">
                  <input
                    type="checkbox"
                    id="angels"
                    name="angels"
                    onChange={() => setFundingAngel(!fundingAngel)}
                    checked={fundingAngel}
                  />
                  <label htmlFor="angels"><strong>Angel investors</strong></label>
                </div>
                <div className="funding-input">
                  <input
                    type="checkbox"
                    id="other_funding"
                    name="other_funding"
                    onChange={() => setFundingOther(!fundingOther)}
                    checked={fundingOther}
                  />
                  <label htmlFor="other_funding"><strong>Other funding</strong></label>
                </div>
              </div>
              {
                fundingOther &&
                <div className="field">
                  <label className="label" htmlFor={'fundingOtherSource'}>
                    <strong>Other funding source</strong>
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'fundingOtherSource'}
                      onChange={handleInputChange(setFundingOtherSource)}
                      id={'fundingOtherSource'}
                      required={isRequired}
                      value={fundingOtherSource}
                    />
                  </div>
                </div>
              }
              {/* Funding requirements*  */}
              <div className="field">
                <label className="label" htmlFor={'fundingRequirements'}>
                  <strong>Funding requirements</strong>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'number'}
                    name={'fundingRequirements'}
                    onChange={handleInputChange(setFundingRequirements)}
                    id={'fundingRequirements'}
                    required={isRequired}
                    min="0" step="1"
                    value={fundingRequirements}
                  />
                </div>
              </div>
              {/* How do you believe SURFnCODE can help?*  */}
              <div className="field">
                <label className="label" htmlFor={'help'}>
                  <strong>How do you believe SURFnCODE can help?</strong>
                </label>
                <div className="control">
                  <textarea
                    className="textarea"
                    name={'help'}
                    onChange={handleInputChange(setHelp)}
                    id={'help'}
                    required={isRequired}
                    rows="6"
                    value={help}
                  />
                </div>
              </div>
              <div className="field">
                <label>
                  Items marked with <strong>*</strong> are required
                </label>
              </div>
              <div className="field">
                <button
                  className={`button is-link ${isSending ? 'submitting' : ''}`}
                  type="submit"
                  disabled={isSending}
                >
                  {isSending ? ' ' : 'Send'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <ToastContainer
        position="top-center"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Modal open={isModalOpen} onClose={consentDenied} center>
        <h2>Your data</h2>
        <div className="gdpr">
          Thank you for applying to SURFnCODE’s Founder Program.
          We’ll use the information provided in this form to get in touch with you to provide updates from this process,
          as well as news about the company or other information we think may be useful to our founder community.
          You can change your mind at anytime by writing to us at hello@surfncode.io.
          We will treat your information with respect.
          In our website you can find more information about our privacy policy.
          Click accept if you agree that we may process your information in accordance to these terms.
        </div>
        <button type="button" onClick={handleSubmit}>Accept</button>
      </Modal>
    </Layout>
  )
}

export default ApplyForm
