import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import ApplyForm from '../components/ApplyForm'

export const ApplyPageTemplate = ({ title, description }) => (
  <ApplyForm
    title={title}
    description={description}
  />
)

ApplyPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ApplyPage = ({ data }) => {
  console.log(data)
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ApplyPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
    </Layout>
  )
}

ApplyPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ApplyPage

export const applyPageQuery = graphql`
  query ApplyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
