import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Contact from '../components/contact'
import Blurb from '../components/blurb'
import WaveFlow from '../components/waveflow'
import Buffer from '../components/buffer'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const SeedTemplate = ({
  title,
  content,
  contentComponent,
  mainPitch,
  subPitch,
  sections,
  highlights,
  contact
}) => {
  const PageContent = contentComponent || Content

  return (
    <>
      <Buffer />
      <Blurb title={mainPitch} body={subPitch}/>
      <WaveFlow sections={sections} />
      <Contact data={contact[0]} />
    </>
  )
}

SeedTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const SeedPage = ({ data }) => {
  console.log(data)
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SeedTemplate
        contentComponent={HTMLContent}
        {...post.frontmatter}
        content={post.html}
      />
    </Layout>
  )
}

export default SeedPage

export const seedPageQuery = graphql`
  query SeedPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        mainPitch
        subPitch
        highlights {
          highlight
        }
        sections {
          title
          body
          quote
        }
        contact{
          title
          body
          button
          address
        }
      }
    }
  }
`
