import React from 'react'
import Layout from '../components/Layout'
import MainPitch from '../components/mainpitch'

const NotFoundPage = () => (
  <Layout>
    <MainPitch
      main={'Oops! Looks like the surf is flat around here'}
      sub={'We couldn\'t find the page you were looking for.'}
      flat
    />
  </Layout>
)

export default NotFoundPage
